@import url(https://fonts.googleapis.com/css?family=Alegreya&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header{background-color:#15223b;width:100%;margin:0;padding:0;color:white}.header h2{margin:0;padding:0}

.device-input{margin-top:20px;margin-left:20px}.device-input .dropdown-toggle{width:35rem}.device-input .dropdown-item{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between}.device-input .dropdown-item:focus{background-color:transparent}.device-input .dropdown-item:active{background-color:transparent}.device-input .dropdown-item .btn.item{-webkit-flex-grow:4;flex-grow:4;text-align:left;text-decoration:none}.device-input .dropdown-item .btn.ctrl{color:#333;opacity:0.5;transition:color ease-in-out .3s}.device-input .dropdown-item .btn.ctrl:hover{color:#cc0000;opacity:1}

.NotFound{background-image:url(/static/media/bg.2fb96095.jpg);background-size:cover;color:white;text-align:center;height:100vh;display:grid;position:relative}.NotFound .content-container{margin:auto}.NotFound .giant-text{margin-top:-300px;font-family:'Alegreya', serif;font-size:15rem;font-weight:600}.NotFound .footer{position:absolute;bottom:10px;font-size:11px;width:100%}

