.device-input {
  margin-top: 20px;
  margin-left: 20px;
  .dropdown-toggle {
    width: 35rem;
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    &:focus {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
    .btn {
      &.item {
        flex-grow: 4;
        text-align: left;
        text-decoration: none;
      }
      &.ctrl {
        color: #333;
        opacity: 0.5;
        transition: color ease-in-out .3s;
        &:hover {
          color: #cc0000;
          opacity: 1;
        }
      }
    }
  }
}
