.header {
  background-color: #15223b;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;

  h2 {
    margin: 0;
    padding: 0;
  }
}