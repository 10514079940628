.NotFound {
  /* ---------- mood theme ---------- */
  background-image: url('/bg.jpg');
  background-size: cover;
  color: white;
  text-align: center;
  height: 100vh;
  display: grid;
  position: relative;

  .content-container {
    margin: auto;
  }

  .giant-text {
    /* ---------- mood theme ---------- */
    margin-top: -300px;

    /* ---------- goat theme ---------- */
    // color: rgba(61, 23, 23, 0.5);

    font-family: 'Alegreya', serif;
    font-size: 15rem;
    font-weight: 600;
  }

  .footer {
    position: absolute;
    bottom: 10px;
    font-size: 11px;
    width: 100%;
  }
}